import { PostData } from "../posts/post.models";

export function computePostSizes(
  posts: PostData[],
  screenHeight: number
): PostData[] {
  let postHeight = Math.max(screenHeight / 2, 400);
  console.debug("recomputing post sizes for postData", postHeight);

  return posts.map((post) => {
    return {
      ...post,
      height: postHeight,
      imageHeight: postHeight,
      videoHeight: postHeight,
    };
  });
}
