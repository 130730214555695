import autoBind from "auto-bind";

import { BasicImagePost, PaginatedArticlePost } from "../posts/post.models";
import {
  CommentGenerationStrategy,
  DateGenerationStrategy,
  FeedGenerationStrategy,
  InteractionGenerationStrategy,
} from "../generation/generation.models";
import { AssetFile } from "./models/asset.models";
import { ExperimentBranding } from "./models/branding.models";
import { ExperimentCondition } from "./models/condition.models";
import { ExperimentManifest } from "./models/manifest.models";
import { Author } from "./models/author.models";
import { CommentBank } from "./models/comment.models";
import { collectAssetFiles } from "../asset/asset_file";
import { loadJSONManifest } from "../asset/manifest";
import { combineUrl } from "../utils/url_utils";

const defaultAuthorBank = "./defaults/author_bank.json";
const defaultCommentBank = "./defaults/comment_bank.json";
const defaultAvatarBank = "./defaults/avatar_bank.json";
const defaultExperimentBranding = "./defaults/experiment_branding.json";
const defaultCommentStrategy = "./defaults/comment_generation.json";
const defaultDateStrategy = "./defaults/date_generation.json";
const defaultLikeStrategy = "./defaults/like_generation.json";
const defaultShareStrategy = "./defaults/share_generation.json";

export class ManifestParser {
  private manifest: ExperimentManifest;

  private conditionName: string;
  private condition?: ExperimentCondition;

  authorBank: Author[];
  commentBank: CommentBank;
  avatarBank: AssetFile[];
  branding: ExperimentBranding;

  readonly articles: PaginatedArticlePost[] = [];
  readonly advertisements: BasicImagePost[] = [];
  readonly infographics: BasicImagePost[] = [];
  readonly memes: BasicImagePost[] = [];
  readonly name: string;
  readonly instructions: string;
  readonly contact: string;
  readonly strategy: FeedGenerationStrategy;
  readonly mobileSupported: boolean;

  commentStrategy!: CommentGenerationStrategy;
  dateStrategy!: DateGenerationStrategy;
  likeStrategy!: InteractionGenerationStrategy;
  shareStrategy!: InteractionGenerationStrategy;

  constructor(manifest: ExperimentManifest, condition: string) {
    autoBind(this);
    this.conditionName = condition;
    this.manifest = manifest;
    this.name = manifest.name;
    this.instructions = manifest.instructions;
    this.contact = manifest.contact;
    this.strategy = manifest.feed_generation;
    this.mobileSupported = manifest.supports_mobile ?? true;
    this.authorBank = [];
    this.commentBank = {};
    this.avatarBank = [];
    this.branding = {};
  }

  async initialize() {
    this.loadConditionData();
    this.loadArticleData();
    this.loadAdvertisementData();
    this.loadInfographics();
    this.loadMemes();

    await this.loadDateStrategy();
    await this.loadCommentStrategy();
    await this.loadLikeStrategy();
    await this.loadShareStrategy();
    await this.loadAuthorBank();
    await this.loadCommentBank();
    await this.loadAvatarBank();
    await this.loadExperimentBranding();
  }

  loadConditionData() {
    this.condition = this.manifest.conditions?.find(
      (condition) => condition.id === this.conditionName
    );
  }

  loadArticleData() {
    if (this.manifest.articles == null) return;
    const articleCollection = this.manifest.articles;

    for (const article of this.manifest.articles.files ?? []) {
      const assetPaths = article.pages.map((page) =>
        combineUrl(
          this.condition?.asset_root,
          articleCollection.asset_root,
          page
        )
      );

      this.articles.push({
        id: article.id ?? "unknown-experiment-article",
        type: "paginated-article",
        pages: assetPaths,
        /// Combine the collection-wide and article specific tags.
        tags: [...(articleCollection.tags ?? []), ...(article.tags ?? [])],
      });
    }
  }

  loadAdvertisementData() {
    if (this.manifest.advertisements == null) return;
    const advertisements = this.manifest.advertisements;
    const files = collectAssetFiles(advertisements, this.condition);

    for (const file of files) {
      this.advertisements.push({
        id: file.id ?? "unknown-experiment-advertisement",
        type: "single-image-view",
        image: file.filename,
        tags: file.tags,
      });
    }
  }

  loadInfographics() {
    if (this.manifest.infographics == null) return;
    const infographics = this.manifest.infographics;
    const files = collectAssetFiles(infographics, this.condition);

    for (const file of files) {
      this.infographics.push({
        id: file.id ?? "unknown-experiment-infographic",
        type: "image-infographic",
        image: file.filename,
        tags: file.tags,
      });
    }
  }

  loadMemes() {
    if (this.manifest.memes == null) return;
    const memes = this.manifest.memes;
    const files = collectAssetFiles(memes, this.condition);

    for (const file of files) {
      this.memes.push({
        id: file.id ?? "unknown-experiment-meme",
        type: "single-image-view",
        image: file.filename,
        tags: file.tags,
      });
    }
  }

  async loadDateStrategy() {
    this.dateStrategy =
      this.strategy.date_generation ??
      (await loadJSONManifest(defaultDateStrategy));
  }

  async loadCommentStrategy() {
    this.commentStrategy =
      this.strategy.comment_strategy ??
      (await loadJSONManifest(defaultCommentStrategy));

    if (this.commentStrategy.date_generation == null) {
      this.commentStrategy.date_generation = this.dateStrategy;
    }
  }

  async loadLikeStrategy() {
    this.likeStrategy =
      this.strategy.like_generation ??
      (await loadJSONManifest(defaultLikeStrategy));
  }

  async loadShareStrategy() {
    this.shareStrategy =
      this.strategy.share_generation ??
      (await loadJSONManifest(defaultShareStrategy));
  }

  async loadAuthorBank() {
    if (this.manifest.author_bank == null) {
      this.authorBank = await loadJSONManifest(defaultAuthorBank);
      return;
    }

    this.authorBank = await loadJSONManifest(
      this.manifest.author_bank,
      this.conditionName
    );
  }

  async loadAvatarBank() {
    // Using the default avatar bank should not combine asset files with the current experiment condition.
    const condition =
      this.manifest.avatars != null ? this.condition : undefined;
    this.avatarBank = collectAssetFiles(
      this.manifest.avatars ?? (await loadJSONManifest(defaultAvatarBank)),
      condition
    );
  }

  async loadCommentBank() {
    this.commentBank = await loadJSONManifest(defaultCommentBank);

    if (this.manifest.comment_bank == null) return;

    this.commentBank = {
      ...this.commentBank,
      ...(await loadJSONManifest(
        this.manifest.comment_bank,
        this.conditionName
      )),
    };
  }

  async loadExperimentBranding() {
    await this.loadDefaultExperimentBranding();

    if (this.manifest.branding == null) return;

    if (this.manifest.branding.name != null) {
      this.branding.name = this.manifest.branding.name;
    }

    if (this.manifest.branding.icon != null) {
      this.branding.icon = this.manifest.branding.icon;
    }
  }

  async loadDefaultExperimentBranding() {
    this.branding = await loadJSONManifest(defaultExperimentBranding);
  }
}
