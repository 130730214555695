/**
 * Combine many parts of a url together, separated by /.
 * @param {...string} parts
 * @returns {string} The joined asset path.
 */
export function combineUrl(...parts: (string | undefined)[]) {
  let path = parts
    .filter((part): part is string => part != null)
    .map((part) =>
      part.endsWith("/") ? part.substring(0, part.length - 1) : part
    )
    .map((part) => (part.startsWith("/") ? part.substring(1) : part))
    .join("/");

  if (!path.startsWith("/")) path = "/" + path;

  return path;
}
