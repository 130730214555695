import { PostData } from "../../posts/post.models";
import { randomNumber } from "../../utils/randomization";
import { InteractionGenerationStrategy } from "../generation.models";

export class InteractionGenerator {
  private strategy: InteractionGenerationStrategy;
  private key: keyof PostData;

  constructor(strategy: InteractionGenerationStrategy, key: keyof PostData) {
    this.strategy = strategy;
    this.key = key;
  }

  addPostInteractions(posts: PostData[]): PostData[] {
    return posts.map((post, index) => {
      const min = this.strategy.min;
      let max = this.strategy.max;

      if (this.strategy.increase_per_post != null) {
        max += this.strategy.increase_per_post * index;
      }

      if (this.strategy.max_cap != null) {
        max = Math.min(this.strategy.max_cap, max);
      }

      let number = randomNumber(min, max);

      return {
        ...post,
        [this.key]: number,
      };
    });
  }
}
