import { ReactNode } from "react";
import { View } from "../../utils/view";
import "./unsupported-feature-screen.component.css";
import { UnsupportedFeatureController } from "./unsupported-feature-screen.controller";
import { UnsupportedFeatureState } from "./unsupported-feature-screen.state";
import { Dialog, Typography } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";

export interface UnsupportedFeatureScreenProps {
  controller: UnsupportedFeatureController;
}

export class UnsupportedFeatureScreen extends View<
  UnsupportedFeatureScreenProps,
  UnsupportedFeatureState,
  UnsupportedFeatureController
> {
  constructor(props: UnsupportedFeatureScreenProps) {
    super(props, props.controller);
  }

  render(): ReactNode {
    return (
      <Dialog
        open={this.state.displaying}
        onClose={this.controller.onDialogClosed}
      >
        <div className="unsupported-feature-dialog">
          <Typography
            variant="h4"
            component="h4"
            className="unsupported-feature-title"
          >
            Feature Unavailable
          </Typography>
          <ConstructionIcon
            className="unsupported-feature-icon"
            fontSize="large"
            color="warning"
          />
          <Typography variant="body1" className="unsupported-feature-body">
            This application is still in active development, and the feature you
            are trying to access is not yet ready for use. Please standby as we
            prepare to dazzle you!
          </Typography>
        </div>
      </Dialog>
    );
  }
}
