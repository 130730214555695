import { ImageInfographic } from "./contents/image-infographic/image-infographic.component";
import SingleImageView from "./contents/single-image-view/single-image-view.component";
import { BuzzfeedQuiz } from "./contents/buzzfeed_quiz/buzzfeed_quiz";
import { YoutubeVideo } from "./contents/youtube-video";
import { UnsupportedPost } from "./contents/unsupported-post";
import { PaginatedArticle } from "./contents/paginated-article/paginated-article.component";
import {
  PostData,
  BasicImagePost,
  YoutubeVideoPost,
  BuzzfeedQuizPost,
  PaginatedArticlePost,
} from "./post.models";

export interface PostBodyProps<PostType extends PostData> {
  data: PostType;
}

export function PostBody<T extends PostData>(props: PostBodyProps<T>) {
  let data;
  switch (props.data.type) {
    case "single-image-view":
      data = props.data as any as BasicImagePost;
      return <SingleImageView {...data} />;
    case "image-infographic":
      data = props.data as any as BasicImagePost;
      return <ImageInfographic {...data} />;
    case "youtube-video":
      data = props.data as any as YoutubeVideoPost;
      return <YoutubeVideo {...data} />;
    case "buzzfeed-quiz":
      data = props.data as any as BuzzfeedQuizPost;
      return <BuzzfeedQuiz {...data} />;
    case "paginated-article":
      data = props.data as any as PaginatedArticlePost;
      return <PaginatedArticle {...data} />;
    default:
      return <UnsupportedPost {...props.data} />;
  }
}
