import { CircularProgress, Dialog, Typography } from "@mui/material";
import "./loading-screen.component.css";
import { Container } from "@mui/system";

export function LoadingScreen() {
  return (
    <Dialog open={true} className="modal-root">
      <Container maxWidth="md" className="loading-screen-outer">
        <Typography variant="h6">
          Loading experiment data and initializing, please wait...
        </Typography>
        <CircularProgress className="loading-screen-progress" size={150} />
      </Container>
    </Dialog>
  );
}
