import { combineUrl } from "../utils/url_utils";

export async function loadJSONManifest<T>(uri: string, condition?: string) {
  let response = await fetch(combineUrl(condition, uri));
  if (!response.ok) response = await fetch(uri);
  if (!response.ok) {
    throw new Error(
      `Failed to load JSON Manifest at ${uri} or ${combineUrl(condition, uri)}!`
    );
  }
  return (await response.json()) as T;
}
