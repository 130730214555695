import autoBind from "auto-bind";

import { shuffle } from "../utils/randomization";
import {
  FeedGenerationStrategy,
  FeedSequenceItem,
  GenerationConstraint,
  SequenceType,
  RandomizationStrategy,
} from "./generation.models";
import { ManifestParser } from "../manifest/parser";
import { PostData } from "../posts/post.models";
import { AuthorGenerator } from "./user_data/author.generator";
import { AvatarGenerator } from "./user_data/avatar.generator";
import { PostDateGenerator } from "./user_data/date.generator";
import { CommentGenerator } from "./user_data/comment.generator";
import { InteractionGenerator } from "./user_data/interaction.generator";
import { dumpCSV } from "../utils/csv_dump";

export class FeedGenerator {
  private parser: ManifestParser;
  private strategy: FeedGenerationStrategy;
  private authorGenerator: AuthorGenerator;
  private avatarGenerator: AvatarGenerator;
  private postDateGenerator: PostDateGenerator;
  private commentGenerator: CommentGenerator;
  private likeGenerator: InteractionGenerator;
  private shareGenerator: InteractionGenerator;

  private articles: PostData[] = [];
  private advertisements: PostData[] = [];
  private infographics: PostData[] = [];
  private memes: PostData[] = [];
  private posts: PostData[] = [];

  private currentArticle = 0;
  private currentAdvertisement = 0;
  private currentInfographic = 0;
  private currentMeme = 0;

  constructor(parser: ManifestParser) {
    autoBind(this);
    this.parser = parser;
    this.strategy = this.parser.strategy;
    this.authorGenerator = new AuthorGenerator(this.parser.authorBank);
    this.avatarGenerator = new AvatarGenerator(this.parser.avatarBank);
    this.commentGenerator = new CommentGenerator(
      this.parser.commentBank,
      this.parser.commentStrategy,
      this.avatarGenerator,
      this.authorGenerator
    );
    this.likeGenerator = new InteractionGenerator(
      this.parser.likeStrategy,
      "likes"
    );
    this.shareGenerator = new InteractionGenerator(
      this.parser.shareStrategy,
      "shares"
    );
    this.postDateGenerator = new PostDateGenerator(this.parser.dateStrategy);
  }

  generate(): PostData[] {
    this.memes = this.parser.memes.slice();
    this.articles = this.parser.articles.slice();
    this.advertisements = this.parser.advertisements.slice();
    this.infographics = this.parser.infographics.slice();

    this.randomizeSets();

    if (
      this.strategy.appearance_order == null ||
      this.strategy.appearance_order.length == 0
    ) {
      /// If we weren't given an appearance order, just show them in line.
      return [
        ...this.articles,
        ...this.advertisements,
        ...this.infographics,
        ...this.memes,
      ];
    }

    let done = false;
    let index = 0;

    while (!done) {
      const item =
        this.strategy.appearance_order[
          index % this.strategy.appearance_order.length
        ];

      done = this.processSequenceItem(item);
      index++;
    }

    this.posts = this.postDateGenerator.addPostDates(this.posts);
    this.posts = this.authorGenerator.addPostAuthors(this.posts);
    this.posts = this.avatarGenerator.addPostAvatars(this.posts);
    this.posts = this.commentGenerator.addPostComments(this.posts);
    this.posts = this.likeGenerator.addPostInteractions(this.posts);
    this.posts = this.shareGenerator.addPostInteractions(this.posts);

    console.debug(
      "feed_generation_stats",
      dumpCSV([
        [
          "post-id",
          "like-count",
          "share-count",
          "comment-count",
          "author",
          "post-date",
        ],
        ...this.posts.map((post) => {
          return [
            post.id,
            post.likes,
            post.shares,
            post.comments?.length,
            post.author,
            post.date,
          ];
        }),
      ])
    );

    return this.posts;
  }

  processSequenceItem(item: FeedSequenceItem): boolean {
    if (this.validateConstraints(this.allConstraints())) {
      return true;
    }

    const type = item.type;

    const min = item.amount.min;
    const max = item.amount.max;
    const numberToAdd = Math.min(Math.trunc(Math.random() * (max - min) + min));

    for (let i = 0; i < numberToAdd; i++) {
      /// If other constraints aren't met, but this item's are, skip the item but iterate again.
      if (this.validateConstraint(this.getConstraintFor(type))) {
        return false;
      }

      const post = this.getNextPostFor(type);
      this.posts.push(post);
    }

    if (this.validateConstraints(this.allConstraints())) {
      return true;
    }

    return false;
  }

  allConstraints(): GenerationConstraint[] {
    return [
      this.getConstraintFor("advertisements"),
      this.getConstraintFor("memes"),
      this.getConstraintFor("infographics"),
      this.getConstraintFor("articles"),
    ].filter(
      (constraint): constraint is GenerationConstraint => constraint != null
    );
  }

  getConstraintFor(type: SequenceType): GenerationConstraint | undefined {
    let constraint;
    switch (type) {
      case "memes":
        constraint = this.strategy.constraints?.memes;
        break;
      case "advertisements":
        constraint = this.strategy.constraints?.advertisements;
        break;
      case "articles":
        constraint = this.strategy.constraints?.articles;
        break;
      case "infographics":
        constraint = this.strategy.constraints?.infographics;
        break;
      default:
        throw new Error(`Unknown appearance order type ${type}`);
    }

    return {
      ...constraint,
      type: type,
    };
  }

  getPostsFor(type: SequenceType): PostData[] {
    switch (type) {
      case "memes":
        return this.memes;
      case "advertisements":
        return this.advertisements;
      case "articles":
        return this.articles;
      case "infographics":
        return this.infographics;
      default:
        throw new Error(`Unknown appearance order type ${type}`);
    }
  }

  getCurrentIndexFor(type: SequenceType): number {
    switch (type) {
      case "memes":
        return this.currentMeme++ % this.memes.length;
      case "advertisements":
        return this.currentAdvertisement++ % this.advertisements.length;
      case "articles":
        return this.currentArticle++ % this.articles.length;
      case "infographics":
        return this.currentInfographic++ % this.infographics.length;
      default:
        throw new Error(`Unknown appearance order type ${type}`);
    }
  }

  getNextPostFor(type: SequenceType): PostData {
    switch (type) {
      case "memes":
        return this.memes[this.currentMeme++ % this.memes.length];
      case "advertisements":
        return this.advertisements[
          this.currentAdvertisement++ % this.advertisements.length
        ];
      case "articles":
        return this.articles[this.currentArticle++ % this.articles.length];
      case "infographics":
        return this.infographics[
          this.currentInfographic++ % this.infographics.length
        ];
      default:
        throw new Error(`Unknown appearance order type ${type}`);
    }
  }

  validateConstraints(constraints: GenerationConstraint[]): boolean {
    return (
      constraints.filter(this.validateConstraint).length === constraints.length
    );
  }

  validateConstraint(constraint: GenerationConstraint | undefined): boolean {
    if (constraint == null) return false;
    if (constraint.type == null) {
      throw new Error(
        "Unable to validate a constraint that doesn't have sequence type attached!"
      );
    }

    let items;
    let currentIndex;

    switch (constraint.type) {
      case "memes":
        items = this.memes;
        currentIndex = this.currentMeme;
        break;
      case "advertisements":
        items = this.advertisements;
        currentIndex = this.currentAdvertisement;
        break;
      case "articles":
        items = this.articles;
        currentIndex = this.currentArticle;
        break;
      case "infographics":
        items = this.infographics;
        currentIndex = this.currentInfographic;
        break;
    }

    /// If the constraint specifies to ensure we use all the items, and we haven't yet, DON'T skip.
    if (constraint.use_all && currentIndex < items.length) return false;

    /// If the constraint specifies to NOT allow repeats, and we've used all our items, SKIP.
    if (!constraint.allow_repeats && currentIndex >= items.length) return true;

    /// If no other constraint criteria was flagged, return false, meaning INCLUDE this next item.
    return false;
  }

  randomizeSets() {
    this.memes = this.randomizeSet(
      this.memes,
      this.strategy.randomization?.memes ?? "fixed_order"
    );
    this.articles = this.randomizeSet(
      this.articles,
      this.strategy.randomization?.articles ?? "fixed_order"
    );
    this.infographics = this.randomizeSet(
      this.infographics,
      this.strategy.randomization?.infographics ?? "fixed_order"
    );
    this.advertisements = this.randomizeSet(
      this.advertisements,
      this.strategy.randomization?.advertisements ?? "fixed_order"
    );
  }

  randomizeSet(set: PostData[], strategy: RandomizationStrategy): PostData[] {
    if (strategy == "fixed_order") return set.slice();

    return shuffle(set);
  }

  get name() {
    return this.parser.name;
  }

  get instructions() {
    return this.parser.instructions;
  }

  get contact() {
    return this.parser.contact;
  }
}
