import { BasicImagePost } from "../../post.models";
import "./single-image-view.component.css";

export interface SingleImageViewProps extends BasicImagePost {}

export default function SingleImageView(props: SingleImageViewProps) {
  const imageStyle = {
    maxHeight: `${props.height}px`,
  };
  return (
    <div id={`${props.id}-container`} className={"single-image-view-container"}>
      <img
        src={props.image}
        className={"single-image-view-cell"}
        id={`${props.id}-image-view`}
        style={imageStyle}
      />
      <p id={`${props.id}-prompt`} className={"single-image-view-prompt"}>
        {props.title}
      </p>
    </div>
  );
}
