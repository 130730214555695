import autoBind from "auto-bind";
import { BehaviorSubject } from "rxjs";

export abstract class Controller {
  constructor() {
    autoBind(this);
  }

  async initialize(): Promise<void> {}
  async dispose(): Promise<void> {}
}

/**
 * A simple wrapper class that works with a state that React would expect for a component.
 * Meant to interact with a View to control business logic and event handling.
 * We use RxJS to create a subject that updates with the current state.
 * Views can listen to that state to update their components without doing unruly setStates everywhere.
 */
export abstract class ReactiveController<State> extends Controller {
  private _state: State;
  private subject: BehaviorSubject<State>;

  constructor(initial: State) {
    super();
    this._state = initial;
    this.subject = new BehaviorSubject(initial);
  }

  async dispose() {
    this.subject.complete();
  }

  setState(state: Partial<State>) {
    this._state = {
      ...this._state,
      ...state,
    };
    this.subject.next(this._state);
  }

  get stream() {
    return this.subject;
  }

  get state(): State {
    return this._state;
  }
}
