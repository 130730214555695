import { BasicImagePost } from "../../post.models";
import "./image-infographic.component.css";

export interface ImageInfographicProps extends BasicImagePost {}

export function ImageInfographic(props: ImageInfographicProps) {
  return (
    <div
      id={`${props.id}-container`}
      className={"infographic-image-view-container"}
      style={{ maxHeight: props.height }}
    >
      <img
        src={props.image}
        className={"infographic-image-view-cell"}
        id={`${props.id}-infographic-view`}
      />
    </div>
  );
}
