import { render } from "react-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import autoBind from "auto-bind";
import { createTheme, CssBaseline } from "@mui/material";
import { View } from "./utils/view";
import { ExperimentController } from "./index.controller";

import "./index.css";

import { ThemeProvider } from "@emotion/react";
import { LoadErrorScreen } from "./components/error-screen/error-screen.component";
import { LoadingScreen } from "./components/loading-screen/loading-screen.component";
import { ExperimentBody } from "./body/body.component";
import { ExperimentState } from "./index.models";

const container = document.getElementById("root");

class ExperimentContainer extends View<
  any,
  ExperimentState,
  ExperimentController
> {
  constructor(props: any) {
    super(props, new ExperimentController());
    autoBind(this);
  }

  render() {
    const theme = createTheme({
      palette: {
        mode: "light",
      },
    });

    let screen;

    if (this.state.error != null) {
      screen = (
        <LoadErrorScreen
          error={this.state.error}
          contact={this.state.contact}
        />
      );
    } else if (
      this.state.screenWidth == null ||
      this.state.screenHeight == null ||
      this.state.posts.length == 0
    ) {
      screen = <LoadingScreen />;
    } else {
      screen = (
        <ExperimentBody
          mobileSupported={this.state.mobileSupported}
          branding={this.state.branding}
          menuController={this.state.menuController}
          notificationCount={this.props.notificationCount}
          version={this.state.version}
          posts={this.state.posts}
          unsupportedFeatureController={this.state.unsupportedFeatureController}
        />
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {screen}
      </ThemeProvider>
    );
  }
}

render(<ExperimentContainer />, container);
