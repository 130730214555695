import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";

import "./right-sidebar.component.css";
import React from "react";
import { Author } from "../../../manifest/models/author.models";

export interface SponsoredPostData {
  id: string;
  title: string;
  url: string;
  image: string;
}

export interface RightSidebarProps {
  sponsored: SponsoredPostData[];
  birthdays: Author[];
  contacts: (Author & { avatar: string })[];
}

export function RightSidebar(props: RightSidebarProps) {
  return (
    <div className="right-sidebar-outer">
      <SponsoredSection {...props} />
      <Divider />
      <BirthdaySection {...props} />
      <Divider />
      <ContactSection {...props} />
    </div>
  );
}

export function SponsoredSection(props: RightSidebarProps) {
  return (
    <List>
      <ListItem disablePadding>
        <ListItemText primary="Sponsored" className="sidebar-section-header" />
      </ListItem>
      {props.sponsored.map((sponsored) => {
        return (
          <ListItem>
            <div
              className="sponsored-post-container"
              id={`${sponsored.id}-sponsored-post`}
            >
              <img className="sponsored-sidebar-image" src={sponsored.image} />
              <div
                className="sponsored-sidebar-text"
                id={`${sponsored.id}-sponsored-text`}
              >
                <div
                  className="sponsored-sidebar-title"
                  id={`${sponsored.id}-sponsored-title`}
                >
                  {sponsored.title}
                </div>
                <div
                  className="sponsored-sidebar-url"
                  id={`${sponsored.id}-sponsored-url`}
                >
                  {sponsored.url}
                </div>
              </div>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}

function contactName(contact: Author): string {
  return `${contact.first} ${contact.last}`;
}

function birthdaySummary(birthdays: Author[]): React.ReactNode {
  if (birthdays.length == 0) {
    return "No birthdays today.";
  }

  const firstBirthday = contactName(birthdays[0]);
  let body: React.ReactNode[];

  if (birthdays.length == 2) {
    body = [
      <BoldedText text={firstBirthday} />,
      " and ",
      <BoldedText text={contactName(birthdays[1])} />,
      " have birthdays today!",
    ];
  } else if (birthdays.length > 2) {
    body = [
      <BoldedText text={firstBirthday} />,
      " and ",
      <BoldedText text={`${birthdays.length} others`} />,
      " have birthdays today!",
    ];
  } else {
    body = [<BoldedText text={firstBirthday} />, "has a birthday today!"];
  }

  return <span className="birthday-summary">{body}</span>;
}

function BoldedText(props: { text: string }) {
  return (
    <Typography variant="body1" component="span" sx={{ fontWeight: "bold" }}>
      {props.text}
    </Typography>
  );
}

export function BirthdaySection(props: RightSidebarProps) {
  return (
    <List>
      <ListItem disablePadding>
        <ListItemText primary="Birthdays" className="sidebar-section-header" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: "secondary.light" }}>
            <CelebrationIcon
              fontSize="large"
              sx={{ color: "primary.light", padding: "0 2px" }}
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={birthdaySummary(props.birthdays)} />
      </ListItem>
    </List>
  );
}

export function ContactSection(props: RightSidebarProps) {
  return (
    <List>
      <ListItem disablePadding>
        <ListItemText primary="Contacts" className="sidebar-section-header" />
      </ListItem>
      {props.contacts.map((contact) => {
        return (
          <ListItem key={`${contact.first} ${contact.last}`}>
            <ListItemAvatar>
              <Avatar
                alt={`${contact.first} ${contact.last}`}
                src={contact.avatar}
              />
            </ListItemAvatar>
            <ListItemText primary={`${contact.first} ${contact.last}`} />
          </ListItem>
        );
      })}
    </List>
  );
}
