import autoBind from "auto-bind";
import { PostData } from "../../posts/post.models";
import { DateRandomizer } from "../../utils/date_randomization";
import { DateFormatter } from "../../utils/date_formatter";
import { DateGenerationStrategy } from "../generation.models";

export class PostDateGenerator {
  private randomizer: DateRandomizer;
  private formatter: DateFormatter;

  constructor(strategy: DateGenerationStrategy) {
    autoBind(this);
    this.formatter = new DateFormatter();
    this.randomizer = new DateRandomizer(
      strategy.min_offset,
      strategy.max_offset,
      strategy.increase_per_post
    );
  }

  addPostDates(posts: PostData[]): PostData[] {
    return posts.map((post) => {
      return {
        ...post,
        date: this.formatter.formatOffsetAsDate(this.randomizer.next()),
      };
    });
  }
}
