import { YoutubeVideoPost } from "../post.models";

export interface YoutubeVideoProps extends YoutubeVideoPost {}

export function YoutubeVideo(props: YoutubeVideoProps) {
  return (
    <div className="youtube-player-container">
      <iframe
        width={props.width}
        height={props.height}
        src={props.url}
        title={props.videoTitle}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
}
