import autoBind from "auto-bind";
import { randomNumber } from "./randomization";

export class DateRandomizer {
  private currentOffset: number;
  private index: number = 0;
  private minimumGapMinutes: number;
  private maximumGapMinutes: number;
  private gapIncrement: number;

  constructor(
    minimumGapMinutes: number,
    maximumGapMinutes: number,
    gapIncrement?: number,
    startFrom?: number
  ) {
    this.currentOffset = startFrom ?? 0;
    this.minimumGapMinutes = minimumGapMinutes;
    this.maximumGapMinutes = maximumGapMinutes;
    this.gapIncrement = gapIncrement ?? 0;
    autoBind(this);
  }

  next(): number {
    const min = this.minimumGapMinutes;
    const max = this.maximumGapMinutes + this.gapIncrement * this.index;
    const offsetDelta = randomNumber(min, max);
    this.currentOffset += offsetDelta;
    this.index++;
    return this.currentOffset;
  }

  /**
   * Generate a list of date offsets (in minutes) prior to the current time.
   * List will be ordered from most recent to least recent.
   * @param num The number of dates to generate.
   */
  generateSequence(num: number): number[] {
    const sequence: number[] = [];

    for (let i = 0; i < num; i++) {
      sequence.push(this.next());
    }

    return sequence;
  }

  reset(start?: number) {
    this.currentOffset = start ?? 0;
    this.index = 0;
  }
}
