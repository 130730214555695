import "./version-indicator.component.css";

import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";

import packageData from "../../../package.json";
import { Card, IconButton, Popover, Theme, useMediaQuery } from "@mui/material";
import { useState } from "react";

export function VersionIndicator(props: { experimentVersion?: string }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const useFullDisplay = useMediaQuery("(min-width:945px");

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  if (useFullDisplay) {
    return (
      <div className="version-indicator-container">
        <_VersionIndicator experimentVersion={props.experimentVersion} />
      </div>
    );
  }

  return (
    <div className="version-indicator-container">
      <IconButton onClick={handleClick}>
        <SystemUpdateIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <_VersionIndicator experimentVersion={props.experimentVersion} />
      </Popover>
    </div>
  );
}

function _VersionIndicator(props: { experimentVersion?: string }) {
  return (
    <Card className="version-indicator-inner">
      <div className="experiment-version">
        Experiment: v{props.experimentVersion ?? "1.0.0"}
      </div>
      <div className="framework-version">
        SME Framework: v{packageData.version}
      </div>
    </Card>
  );
}
