import { ReactiveController } from "../utils/controller";
import {
  PostData,
  PostEvent,
  PostStateMixin,
  UserLikeState,
} from "./post.models";

export class PostController<T extends PostData> extends ReactiveController<
  T & PostStateMixin
> {
  private originalLikeCount: number;
  private originalShareCount: number;

  private _onRepliesClicked?: PostEvent;
  private _onLikeClicked?: PostEvent;
  private _onDislikeClicked?: PostEvent;
  private _onShareClicked?: PostEvent;

  constructor(post: T) {
    super({
      ...post,
      userShared: false,
      displayShareSnackbar: false,
      userLikeState: "none",
      viewingReplies: false,
    });
    this.originalLikeCount = post.likes ?? 0;
    this.originalShareCount = post.shares ?? 0;
  }

  onRepliesClicked() {
    this.setState({
      ...this.state,
      viewingReplies: true,
    });
    if (this._onRepliesClicked != null) this._onRepliesClicked(this.state);
  }

  onRepliesClosed() {
    this.setState({
      ...this.state,
      viewingReplies: false,
    });
  }

  onLikeClicked() {
    let modifiedLikeCount: number = this.originalLikeCount;
    let modifiedLikeState: UserLikeState = this.state.userLikeState;

    switch (this.state.userLikeState) {
      case "user-liked":
        modifiedLikeState = "none";
        break;
      case "none":
      case "user-disliked":
        modifiedLikeState = "user-liked";
        modifiedLikeCount++;
        break;
    }

    this.setState({
      ...this.state,
      likes: modifiedLikeCount,
      userLikeState: modifiedLikeState,
    });

    if (this._onLikeClicked != null) this._onLikeClicked(this.state);
  }

  onDislikeClicked() {
    let modifiedLikeCount = this.originalLikeCount;
    let modifiedLikeState = this.state.userLikeState;

    switch (this.state.userLikeState) {
      case "user-disliked":
        modifiedLikeState = "none";
        break;
      case "none":
      case "user-liked":
        modifiedLikeState = "user-disliked";
        modifiedLikeCount--;
        break;
    }

    this.setState({
      ...this.state,
      likes: modifiedLikeCount,
      userLikeState: modifiedLikeState,
    });

    if (this._onDislikeClicked != null) this._onDislikeClicked(this.state);
  }

  onShareClicked() {
    if (this.state.userShared) return;

    this.setState({
      ...this.state,
      userShared: true,
      displayShareSnackbar: true,
      shares: this.originalShareCount + 1,
    });
    if (this._onShareClicked != null) this._onShareClicked(this.state);
  }

  onShareDismissed() {
    if (!this.state.userShared) return;

    this.setState({
      ...this.state,
      displayShareSnackbar: false,
    });
  }
}
