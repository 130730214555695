import { ExperimentBodyProps } from "../props";
import { TopMenuBar } from "../../components/menu/top-menu.component/top-menu.component";
import { BottomMenuBar } from "../../components/menu/bottom-menu.component/bottom-menu.component";
import { VersionIndicator } from "../../components/version-indicator/version-indicator.component";
import { SocialMediaFeed } from "../../feed/social_media_feed";
import { LeftSidebar } from "./left-sidebar/left-sidebar.component";
import { RightSidebar } from "./right-sidebar/right-sidebar.component";
import { UnsupportedFeatureController } from "../../components/unsupported-feature-screen/unsupported-feature-screen.controller";
import "./desktop-body.component.css";
import { UnsupportedFeatureScreen } from "../../components/unsupported-feature-screen/unsupported-feature-screen.component";

export function DesktopBody(props: ExperimentBodyProps) {
  return (
    <div className="experiment-body-container">
      <TopMenuBar
        notificationCount={5}
        branding={props.branding}
        controller={props.menuController}
      />
      <div className="feed-flex-container">
        <div className="left-sidebar-container">
          <LeftSidebar
            pageCount={5}
            shortcuts={[
              { name: "The Sleep Society" },
              { name: "Fatigue Fighters" },
              { name: "Community Corner" },
              { name: "Vibe Tribe" },
              { name: "Creative Minds" },
            ]}
          />
        </div>
        <div className="feed-container">
          <SocialMediaFeed
            posts={props.posts}
            onLikePostClicked={
              props.unsupportedFeatureController.onUnsupportedFeatureInvoked
            }
            onDislikePostClicked={
              props.unsupportedFeatureController.onUnsupportedFeatureInvoked
            }
            onSharePostClicked={
              props.unsupportedFeatureController.onUnsupportedFeatureInvoked
            }
            onCommentsClicked={
              props.unsupportedFeatureController.onUnsupportedFeatureInvoked
            }
          />
        </div>
        <div className="right-sidebar-container">
          <RightSidebar
            sponsored={[
              {
                image: "/gain/advertisements/purse-ad.png",
                id: "purse-ad",
                title: "Leatherly Hand Bags",
                url: "leatherly.com",
              },
            ]}
            birthdays={[
              { first: "Uri", last: "Goldberg" },
              { first: "Ian", last: "Davis" },
            ]}
            contacts={[
              { first: "Uri", last: "Goldberg", avatar: "/avatars/1.jpg" },
              { first: "Ian", last: "Davis", avatar: "/avatars/2.jpg" },
            ]}
          />
        </div>
      </div>
      <VersionIndicator experimentVersion={props.version} />
      <BottomMenuBar
        notificationCount={5}
        branding={props.branding}
        controller={props.menuController}
      />
      <UnsupportedFeatureScreen
        controller={props.unsupportedFeatureController}
      />
    </div>
  );
}
