import { Container } from "@mui/material";
import { ExperimentBodyProps } from "../props";
import { TopMenuBar } from "../../components/menu/top-menu.component/top-menu.component";
import { SocialMediaFeed } from "../../feed/social_media_feed";
import { BottomMenuBar } from "../../components/menu/bottom-menu.component/bottom-menu.component";
import { VersionIndicator } from "../../components/version-indicator/version-indicator.component";
import "./mobile-body.component.css";

export function MobileBody(props: ExperimentBodyProps) {
  return (
    <div className="experiment-body-container">
      <TopMenuBar
        notificationCount={5}
        branding={props.branding}
        controller={props.menuController}
      />
      <SocialMediaFeed
        posts={props.posts}
        onLikePostClicked={
          props.unsupportedFeatureController.onUnsupportedFeatureInvoked
        }
        onDislikePostClicked={
          props.unsupportedFeatureController.onUnsupportedFeatureInvoked
        }
        onSharePostClicked={
          props.unsupportedFeatureController.onUnsupportedFeatureInvoked
        }
        onCommentsClicked={
          props.unsupportedFeatureController.onUnsupportedFeatureInvoked
        }
      />
      <VersionIndicator experimentVersion={props.version} />
      <BottomMenuBar
        notificationCount={5}
        branding={props.branding}
        controller={props.menuController}
      />
    </div>
  );
}
