const minutesPerHour = 60;
const minutesPerDay = minutesPerHour * 24;
const minutesPerWeek = minutesPerDay * 7;
const minutesPerTwoWeeks = minutesPerWeek * 2;

export class DateFormatter {
  prettyDateTimeString(date: Date): string {
    const month = String(date.getMonth()).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = String(date.getFullYear()).padStart(4, "0");
    let twelveHourAdjusted =
      date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    const hour = String(twelveHourAdjusted).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const meridian = date.getHours() > 12 ? "PM" : "AM";
    return `${month}/${day}/${year} ${hour}:${minute} ${meridian}`;
  }

  formatOffsetAsDate(offsetMinutes: number): string {
    let timeDescription;
    if (offsetMinutes > minutesPerTwoWeeks) {
      const offsetMillis = offsetMinutes * 60 * 1000;
      const date = new Date(new Date().getTime() - offsetMillis);
      return this.prettyDateTimeString(date);
    }
    if (offsetMinutes > minutesPerWeek) {
      const weeks = Math.trunc(offsetMinutes / minutesPerWeek);
      timeDescription = `${weeks} week${weeks > 1 ? "s" : ""}`;
    } else if (offsetMinutes > minutesPerDay) {
      const days = Math.trunc(offsetMinutes / minutesPerDay);
      timeDescription = `${days} day${days > 1 ? "s" : ""}`;
    } else if (offsetMinutes > minutesPerHour) {
      const hours = Math.trunc(offsetMinutes / minutesPerHour);
      timeDescription = `${hours} hour${hours > 1 ? "s" : ""}`;
    } else if (offsetMinutes > 30) {
      timeDescription = `Half an hour`;
    } else {
      timeDescription = `${offsetMinutes} minute${
        offsetMinutes > 1 ? "s" : ""
      }`;
    }

    return `${timeDescription} ago`;
  }
}
