import { AssetCollection, AssetFile } from "../manifest/models/asset.models";
import { ExperimentCondition } from "../manifest/models/condition.models";
import { combineUrl } from "../utils/url_utils";

export function collectAssetFiles(
  collection: AssetCollection,
  condition?: ExperimentCondition
): AssetFile[] {
  if (collection.files != null) {
    return collection.files.map((file) => {
      return {
        ...file,
        filename: combineUrl(
          condition?.asset_root,
          collection.asset_root,
          file.filename
        ),
        tags: [...(file.tags ?? []), ...(collection.tags ?? [])],
      };
    });
  } else if (collection.file_index != null) {
    return collectAssetIndex(collection, condition);
  } else {
    throw new Error(
      `FATAL: No file list or file index specified for asset collection ${collection.id}`
    );
  }
}

function collectAssetIndex(
  collection: AssetCollection,
  condition?: ExperimentCondition
): AssetFile[] {
  const files: AssetFile[] = [];
  const index = collection.file_index!;
  const tagIndex = index.file_tags;

  for (let i = index.start_index; i < index.total_files; i++) {
    const id = index.id_pattern.replace("{index}", i.toString());
    const filename = combineUrl(
      condition?.asset_root,
      collection.asset_root,
      `${i}.${index.file_extension}`
    );

    /// Combine the collection tags with any file specific tags found by referencing the current index id
    /// with the file_tags TagIndex.
    let tags: string[] = [
      ...(collection.tags ?? []),
      ...(tagIndex != null ? tagIndex[id] ?? tagIndex[i] ?? [] : []),
    ];

    files.push({
      id: id,
      filename: filename,
      tags: tags,
    });
  }

  return files;
}
