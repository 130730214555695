import autoBind from "auto-bind";
import React from "react";
import { Subscription } from "rxjs";

/**
 * A subscribing component manages any number of subscriptions across the component lifecycle.
 * The main utility here is to ensure that all the component's subscriptions get cleaned up before the component is disposed of.
 */
export class SubscribingComponent<Props, State> extends React.Component<
  Props,
  State
> {
  private subscriptions: Subscription[] = [];

  constructor(props: Props) {
    super(props);
    autoBind(this);
  }

  subscribe(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  componentWillUnmount(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
