import { BuzzfeedResponseComponent } from "../response/response.component";

/**
 * @callback ResponseSelectedCallback
 * @param {BuzzfeedQuestion} question The question a response was selected for.
 * @param {BuzzfeedResponse} response The response that was selected.
 */

/**
 * Represent a single question in a buzzfeed quiz.
 * @param {object} props -- Component inputs.
 * @param {BuzzfeedQuestion} props.question -- The question data to render.
 * @param {string} props.id -- The id prefix to use for elements on this quiz.
 * @param {ResponseSelectedCallback} props.onResponseSelected
 */
export function BuzzfeedQuestionComponent(props) {
  const id = `${props.id}-question-${props.question.name}`;
  const responses = props.question.responses;
  const firstResponse = responses[0];
  const firstResponseId = `${id}-response-${firstResponse.value}`;

  const responseComponents = [
    <BuzzfeedResponseComponent
      response={firstResponse}
      id={firstResponseId}
      onResponseSelected={props.onResponseSelected}
      isFirstRow={true}
      isLastRow={false}
      key={firstResponseId}
    />,
  ];

  for (const response of responses.slice(1, responses.length - 2)) {
    const responseId = `${id}-response-${response.value}`;
    responseComponents.push(
      <BuzzfeedResponseComponent
        response={response}
        id={responseId}
        onResponseSelected={props.onResponseSelected}
        isFirstRow={false}
        isLastRow={false}
        key={responseId}
      />
    );
  }

  const lastResponse = responses[responses.length - 1];

  const responseId = `${id}=response-${lastResponse.value}`;
  responseComponents.push(
    <BuzzfeedResponseComponent
      response={responses[responses.length - 1]}
      id={responseId}
      onResponseSelected={props.onResponseSelected}
      isFirstRow={false}
      key={responseId}
      isLastRow={true}
    />
  );

  return <div id={`${props.id}-buzzfeed-question`}>responseComponents</div>;
}
