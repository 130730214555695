import "./mobile-menu.component.css";
import { MenuPage } from "../menu.controller";
import { Badge, Paper, TextField, Typography } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { ExperimentBranding } from "../../../manifest/models/branding.models";

export function MobileTopMenu(props: {
  branding: ExperimentBranding | undefined;
  currentPage: MenuPage;
  notificationCount: number;
}) {
  if (props.branding == null) {
    return <div className="no-branding"></div>;
  }

  return (
    <Paper className="top-menu-outer">
      <BrandingBar branding={props.branding} />
      <UserBar notificationCount={props.notificationCount} />
    </Paper>
  );
}

export function UserBar(props: { notificationCount: number }) {
  return (
    <div className="user-bar-container">
      <AddCircleOutlineOutlinedIcon className="user-bar-icon" />
      <ChatOutlinedIcon className="user-bar-icon" />
      <Badge
        badgeContent={props.notificationCount}
        color="primary"
        className="notification-badge-container"
      >
        <NotificationsActiveOutlinedIcon className="notification-icon user-bar-icon" />
      </Badge>
    </div>
  );
}

export function BrandingBar(props: { branding: ExperimentBranding }) {
  return (
    <div className="branding-container">
      <img
        src={props.branding.icon}
        className="branding-icon"
        id="branding-icon"
      />
      <Typography id="branding-name" color="primary">
        {props.branding.name}
      </Typography>
    </div>
  );
}

export function SearchField(props: { branding: ExperimentBranding }) {
  return (
    <TextField
      id="experiment-search-bar"
      className="search-bar"
      variant="filled"
      placeholder={`Search ${props.branding.name}`}
    />
  );
}
