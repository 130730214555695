import { ReactNode } from "react";
import { View } from "../../../utils/view";
import "./paginated-article.component.css";
import {
  PaginatedArticleController,
  PaginatedArticleState,
} from "./paginated-article.controller";
import SingleImageView from "../single-image-view/single-image-view.component";
import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Pagination,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PaginatedArticlePost } from "../../post.models";

export interface PaginatedArticleProps extends PaginatedArticlePost {}

export interface PaginatedComponentProp extends PaginatedArticlePost {
  controller: PaginatedArticleController;
}

export class PaginatedArticle extends View<
  PaginatedArticleProps,
  PaginatedArticleState,
  PaginatedArticleController
> {
  constructor(props: PaginatedArticleProps) {
    super(props, new PaginatedArticleController());
  }

  render(): ReactNode {
    return (
      <div id="paginated-article-container">
        <PaginatedArticleBody {...this.props} controller={this.controller} />
        <PaginatedArticlePopup
          {...this.props}
          controller={this.controller}
          open={this.state.open}
          page={this.state.page}
        />
      </div>
    );
  }
}

export function PaginatedArticleBody(props: PaginatedComponentProp) {
  /// Just display the first page as a single image view with an elevated look for clickability.
  return (
    <Button
      variant="outlined"
      onClick={props.controller.onArticleBodyClicked}
      className="article-button"
    >
      <SingleImageView {...props} image={props.pages[0]} />
    </Button>
  );
}

export function PaginatedArticlePopup(
  props: PaginatedComponentProp & { open: boolean; page: number }
) {
  return (
    <Modal
      open={props.open}
      onClose={props.controller.onModalClosed}
      className="modal-root"
    >
      <Container maxWidth="md" className="paginated-article-top">
        <Stack maxWidth="md" className="paginated-article-outer">
          <div className="top-bar-container">
            <IconButton
              size="small"
              aria-label="close"
              color="primary"
              onClick={props.controller.onModalClosed}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="paginated-article-inner">
            <img
              src={props.pages[props.page - 1]}
              className="paginated-article-content"
            />
          </div>
          <Pagination
            count={props.pages.length}
            page={props.page}
            onChange={props.controller.onPageChanged}
            className="pagination-bottom-bar"
          />
        </Stack>
      </Container>
    </Modal>
  );
}
