import { ReactiveController } from "../../../utils/controller";
import $ from "jquery";

export interface PaginatedArticleState {
  open: boolean;
  page: number;
}

export class PaginatedArticleController extends ReactiveController<PaginatedArticleState> {
  constructor() {
    super({
      open: false,
      page: 1,
    });
  }

  onArticleBodyClicked() {
    this.setState({
      open: true,
    });
  }

  onModalClosed() {
    this.setState({
      open: false,
    });
  }

  onPageChanged(event: React.ChangeEvent<unknown>, page: number) {
    $(".paginated-article-inner").scrollTop(0);
    this.setState({
      page: page,
    });
  }
}
