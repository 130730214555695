import { ReactNode } from "react";
import { View } from "../../../utils/view";
import "./top-menu.component.css";
import { MenuController, MenuPage, MenuState } from "../menu.controller";
import { DesktopTopMenu } from "../desktop-menu.component/desktop-menu.component";
import { useMediaQuery } from "@mui/material";
import { MobileTopMenu } from "../mobile-menu.component/mobile-menu.component";
import { ExperimentBranding } from "../../../manifest/models/branding.models";

export interface TopMenuProps {
  controller: MenuController;
  notificationCount: number;
  branding: ExperimentBranding | undefined;
}

export class TopMenuBar extends View<TopMenuProps, MenuState, MenuController> {
  constructor(props: TopMenuProps) {
    super(props, props.controller);
  }

  render(): ReactNode {
    return (
      <NavigationSizer
        branding={this.props.branding}
        notificationCount={this.props.notificationCount}
        currentPage={this.state.currentPage}
        controller={this.controller}
      />
    );
  }
}

export function NavigationSizer(props: {
  branding: ExperimentBranding | undefined;
  notificationCount: number;
  currentPage: MenuPage;
  controller: MenuController;
}) {
  const largeMode = useMediaQuery(props.controller.mobileCutoffQuery);

  if (largeMode) {
    return (
      <DesktopTopMenu
        branding={props.branding}
        currentPage={props.currentPage}
        notificationCount={props.notificationCount}
      />
    );
  } else {
    return (
      <MobileTopMenu
        branding={props.branding}
        currentPage={props.currentPage}
        notificationCount={props.notificationCount}
      />
    );
  }
}
