import { useMediaQuery } from "@mui/material";
import { DesktopBody } from "./desktop-body.component/desktop-body.component";
import { MobileBody } from "./mobile-body.component/mobile-body.component";
import { ExperimentBodyProps } from "./props";

import "./body.component.css";

export function ExperimentBody(props: ExperimentBodyProps) {
  const useDesktop = useMediaQuery(props.menuController.mobileCutoffQuery);

  if (useDesktop || !props.mobileSupported) {
    /// If our screen is big enough, or if mobile support is turned off in the experiment manifest,
    /// Display the desktop screen with both sidebars.
    return <DesktopBody {...props} />;
  } else {
    return <MobileBody {...props} />;
  }
}
