import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import PeopleIcon from "@mui/icons-material/People";
import GroupsIcon from "@mui/icons-material/Groups";

import "./left-sidebar.component.css";

export interface Shortcut {
  name: string;
}

export interface LeftSidebarProps {
  pageCount: number;
  shortcuts: Shortcut[];
}

export function LeftSidebar(props: LeftSidebarProps) {
  return (
    <div className="left-sidebar-outer">
      <List>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <OutlinedFlagIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primary="Pages"
              secondary={`${props.pageCount} new`}
              className="sidebar-title page-count-title"
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <PeopleIcon fontSize="large" color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Friends" className="sidebar-title" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              <GroupsIcon fontSize="large" color="success" />
            </ListItemIcon>
            <ListItemText primary="Groups" className="sidebar-title" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <ShortcutList shortcuts={props.shortcuts} />
    </div>
  );
}

export function ShortcutList(props: { shortcuts: Shortcut[] }) {
  return (
    <List>
      <ListItem disablePadding>
        <ListItemText primary="Shortcuts" className="sidebar-section-header" />
      </ListItem>
      {props.shortcuts.map((shortcut) => {
        return (
          <ListItem key={shortcut.name}>
            <ListItemButton>
              <ListItemText primary={shortcut.name} className="sidebar-title" />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
