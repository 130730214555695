import { ReactiveController } from "../../utils/controller";

export type MenuPage = "home" | "videos" | "groups" | "dashboard";

export interface MenuState {
  currentPage: MenuPage;
}

export class MenuController extends ReactiveController<MenuState> {
  onHomeClicked() {
    this.setState({
      currentPage: "home",
    });
  }

  onVideosClicked() {
    this.setState({
      currentPage: "videos",
    });
  }

  onGroupsClicked() {
    this.setState({
      currentPage: "groups",
    });
  }

  onDashboardClicked() {
    this.setState({
      currentPage: "dashboard",
    });
  }

  get mobileCutoffQuery() {
    return "(min-width:1024px)";
  }
}
