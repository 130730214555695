import { ShuffledCyclingBank } from "../../utils/collections/shuffled_cycling_bank";

import { PostData } from "../../posts/post.models";
import { Author } from "../../manifest/models/author.models";

export class AuthorGenerator extends ShuffledCyclingBank<Author> {
  addPostAuthors(posts: PostData[]): PostData[] {
    return posts.map((post) => {
      const author = this.getNextObject();
      return {
        ...post,
        author: `${author.first} ${author.last}`,
      };
    });
  }
}
