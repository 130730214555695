/**
 * Represent a response to a single buzzfeed quiz question.
 * @param {object} props
 * @param {BuzzfeedResponse} props.response
 * @param {string} props.id
 * @param {ResponseSelectedCallback} props.onResponseSelected
 * @param {boolean} props.isFirstRow
 * @param {boolean} props.isLastRow
 */
export function BuzzfeedResponseComponent(props) {
  const className = `buzzfeed-question-response-row${
    props.isFirstRow ? " buzzfeed-first-response-row" : ""
  }${props.isLastRow ? " buzzfeed-last-response-row" : ""}`;
}
