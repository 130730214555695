import { ShuffledCyclingBank } from "../../utils/collections/shuffled_cycling_bank";
import { PostData } from "../../posts/post.models";
import { AssetFile } from "../../manifest/models/asset.models";

export class AvatarGenerator extends ShuffledCyclingBank<AssetFile> {
  addPostAvatars(posts: PostData[]): PostData[] {
    return posts.map((post) => {
      const avatar = this.getNextObject();
      return {
        ...post,
        avatar: avatar.filename,
      };
    });
  }
}
