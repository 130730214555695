import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { MenuPage } from "../menu.controller";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

import "./navigation-bar.component.css";

export function NavigationBar(props: { page: MenuPage }) {
  return (
    <div className="navigation-container">
      <BottomNavigation className="navigation-bar" value={props.page}>
        <BottomNavigationAction
          label="Home"
          value="home"
          icon={<HomeOutlinedIcon className="navigation-icon" />}
        />
        <BottomNavigationAction
          label="Videos"
          value="videos"
          icon={<OndemandVideoOutlinedIcon className="navigation-icon" />}
        />
        <BottomNavigationAction
          label="Groups"
          value="groups"
          icon={<Groups3OutlinedIcon className="navigation-icon" />}
        />
        <BottomNavigationAction
          label="Dashboard"
          value="dashboard"
          icon={<DashboardOutlinedIcon className="navigation-icon" />}
        />
      </BottomNavigation>
    </div>
  );
}
