import autoBind from "auto-bind";
import { PostComment, PostData } from "../../posts/post.models";
import { ShuffledCyclingBank } from "../../utils/collections/shuffled_cycling_bank";
import { CommentGenerationStrategy } from "../generation.models";
import { randomNumber } from "../../utils/randomization";
import { DateRandomizer } from "../../utils/date_randomization";
import { DateFormatter } from "../../utils/date_formatter";
import { AvatarGenerator } from "./avatar.generator";
import { AuthorGenerator } from "./author.generator";
import { kebabCase } from "lodash";

export class CommentGenerator {
  readonly bank: { [tag: string]: ShuffledCyclingBank<string> };
  readonly tagBank: ShuffledCyclingBank<string>;

  private strategy: CommentGenerationStrategy;
  private avatarGenerator?: AvatarGenerator;
  private authorGenerator?: AuthorGenerator;
  private randomizer: DateRandomizer;
  private formatter: DateFormatter;

  constructor(
    bank: { [tag: string]: string[] },
    strategy: CommentGenerationStrategy,
    avatarGenerator?: AvatarGenerator,
    authorGenerator?: AuthorGenerator
  ) {
    this.bank = {};
    const tags = Object.keys(bank);
    this.tagBank = new ShuffledCyclingBank(tags);
    console.debug("Generating comments using these tags", tags);

    for (const key of tags) {
      const comments = bank[key];
      this.bank[key] = new ShuffledCyclingBank(comments);
    }

    this.strategy = strategy;
    this.avatarGenerator = avatarGenerator;
    this.authorGenerator = authorGenerator;

    this.formatter = new DateFormatter();

    if (this.strategy.date_generation == null) {
      throw new Error(
        "FATAL: Date Generation Strategy not found for comments!"
      );
    }

    this.randomizer = new DateRandomizer(
      this.strategy.date_generation?.min_offset,
      this.strategy.date_generation?.max_offset,
      this.strategy.date_generation?.increase_per_post
    );

    autoBind(this);
  }

  addPostComments(data: PostData[]): PostData[] {
    return data.map((post, index) => {
      const min = this.strategy.min_comments;
      let max = this.strategy.max_comments;

      if (this.strategy.increase_per_post != null) {
        max += this.strategy.increase_per_post * index;
      }

      if (this.strategy.max_cap != null) {
        max = Math.min(this.strategy.max_cap, max);
      }

      let comments: PostComment[] = [];
      let numComments = randomNumber(min, max);
      let tags = post.tags ?? ["default"];
      if (tags.length == 0) tags.push("default");

      let tagBank = new ShuffledCyclingBank(tags);

      for (let i = 0; i < numComments; i++) {
        let comment;

        const tag = tagBank.getNextObject();
        const bank = this.bank[tag];

        if (bank == null) {
          console.debug("post that caused error", post);
          console.debug("available banks when error occurred", this.bank);
          throw new Error(
            `FATAL: Cannot generate comment from tag ${tag} as no bank exists for that tag!`
          );
        }

        comment = bank.getNextObject();

        const author = this.authorGenerator?.getNextObject();

        comments.push({
          id: this.commentToId(comment),
          body: comment,
          date: this.formatter.formatOffsetAsDate(this.randomizer.next()),
          avatar: this.avatarGenerator?.getNextObject().filename,
          author:
            author != null ? `${author?.first} ${author?.last}` : undefined,
        });
      }

      return {
        ...post,
        comments: comments,
      };
    });
  }

  commentToId(comment: string): string {
    return kebabCase(comment.substring(0, 10));
  }
}
