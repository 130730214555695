import { SxProps } from "@mui/material";

export const CommentList: SxProps = {
  maxHeight: "300px",
  overflowY: "auto",
};

export const CommentText: SxProps = {
  fontSize: "0.65rem",
};
