import { ReactiveController } from "../../utils/controller";
import { UnsupportedFeatureState } from "./unsupported-feature-screen.state";

export class UnsupportedFeatureController extends ReactiveController<UnsupportedFeatureState> {
  constructor() {
    super({
      displaying: false,
    });
  }

  onUnsupportedFeatureInvoked() {
    console.debug("onUnsupportedFeatureInvoked()");
    this.setState({ displaying: true });
  }

  onDialogClosed() {
    this.setState({ displaying: false });
  }
}
