import { Paper, useMediaQuery } from "@mui/material";
import { MenuController, MenuPage, MenuState } from "../menu.controller";

import "./bottom-menu.component.css";
import { View } from "../../../utils/view";
import { ReactNode } from "react";
import { NavigationBar } from "../navigation-bar.component/navigation-bar.component";
import { ExperimentBranding } from "../../../manifest/models/branding.models";

export interface BottomMenuProps {
  notificationCount: number;
  branding: ExperimentBranding | undefined;
  controller: MenuController;
}

export class BottomMenuBar extends View<
  BottomMenuProps,
  MenuState,
  MenuController
> {
  constructor(props: BottomMenuProps) {
    super(props, props.controller);
  }

  render(): ReactNode {
    return (
      <NavigationSizer
        branding={this.props.branding}
        currentPage={this.state.currentPage}
        notificationCount={this.props.notificationCount}
        controller={this.controller}
      />
    );
  }
}

export function NavigationSizer(props: {
  branding: ExperimentBranding | undefined;
  notificationCount: number;
  currentPage: MenuPage;
  controller: MenuController;
}) {
  const largeMode = useMediaQuery(props.controller.mobileCutoffQuery);
  if (largeMode) return <div></div>;

  return (
    <Paper className="bottom-navigation-container">
      <NavigationBar page={props.currentPage} />
    </Paper>
  );
}
