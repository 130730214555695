import autoBind from "auto-bind";
import { BehaviorSubject, debounceTime } from "rxjs";

export interface Size {
  width: number;
  height: number;
}

/**
 * Compute the current client screen size by listening for resize events.
 * Emit the current size on a reactive subject for any consumer to listen to.
 */
export class ScreenSizeListener {
  private subject: BehaviorSubject<Size | undefined> = new BehaviorSubject<
    Size | undefined
  >(undefined);

  constructor() {
    autoBind(this);
  }

  initialize() {
    setTimeout(this.grabDimensions, 0);
    window.addEventListener("resize", this.grabDimensions);
  }

  dispose() {
    window.removeEventListener("resize", this.grabDimensions);
    this.subject.complete();
  }

  get stream() {
    return this.subject.asObservable().pipe(debounceTime(500));
  }

  grabDimensions() {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    this.subject.next({
      width: screenWidth,
      height: screenHeight,
    });
  }
}
