import { Container, Dialog, Typography } from "@mui/material";
import "./error-screen.component.css";
import { ExperimentLoadError } from "../../index.models";

export function LoadErrorScreen(props: {
  error: ExperimentLoadError;
  contact?: string;
}) {
  let message;

  switch (props.error) {
    case "no-manifest":
      message = `
          FATAL Error when loading experiment_manifest! 
          If you are a participant seeing this message, please contact your Experiment POC for assistance.
          This is likely a misconfiguration of your server. Ensure that your web server is
          hosting your experiment_manifest.json file at
          /experiment_manifest.json and reload.
          
        `;
      break;
    case "no-condition":
    case "no-subject-id":
    default:
      let contactMessage = <span>experiment POC</span>;

      if (props.contact != null) {
        contactMessage = (
          <span>
            experiment POC (
            <a href={`mailto:${props.contact}`}>{props.contact}</a>)
          </span>
        );
      }

      message = (
        <Typography>
          The experiment link you entered is invalid. Please verify you copied
          the entire link provided to you. If this issue persists, please
          contact your {contactMessage} to receive a new link.
        </Typography>
      );
      break;
  }

  return (
    <Dialog open={true} className="modal-root">
      <Container maxWidth="md" className="error-container-outer">
        {message}
      </Container>
    </Dialog>
  );
}
