import { Divider, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import "./author-bar.component.css";
import { PostData } from "../post.models";

export interface AuthorBarProps extends PostData {}

export function AuthorBar(props: AuthorBarProps) {
  return (
    <div className="author-bar-outer" id={`${props.id}-title-outer-container`}>
      <div
        className="author-bar-inner"
        id={`${props.id}-title-inner-container`}
      >
        <ProfilePicture
          picture={props.avatar}
          id={`${props.id}-profile-picture`}
        />
        <div
          className="author-name-container"
          id={`${props.id}-author-name-container`}
        >
          <span
            className={`author-name${
              props.advertisementStyle ? " sponsored" : ""
            }`}
            id={`${props.id}-author-name`}
          >
            {props.advertisementStyle ? "Sponsored" : props.author}
          </span>
          <span className="post-date" id={`${props.id}-post-date`}>
            {props.date}
            <IconButton sx={{ padding: "0 5px" }}>
              <SettingsIcon fontSize="small" />
            </IconButton>
          </span>
        </div>
        <div className="post-actions" id={`${props.id}-post-actions`}>
          <IconButton sx={{ padding: "0 5px" }}>
            <MoreHorizIcon />
          </IconButton>
        </div>
      </div>
      <Divider className="divider" />
    </div>
  );
}

function ProfilePicture(props: { picture?: string; id: string }) {
  if (props.picture == null) {
    return null;
  }

  return <img src={props.picture} className="profile-picture" />;
}
