import { BuzzfeedQuizPost } from "../../post.models";
import "./buzzfeed_quiz.css";

import { BuzzfeedQuestionComponent } from "./components/question/question.component";

import { BuzzfeedQuestion } from "./models/question.model";
import { BuzzfeedResponse } from "./models/response.model";

export type ResponseSelectedCallback = (
  question: BuzzfeedQuestion,
  response: BuzzfeedResponse
) => void;

export interface BuzzfeedQuizProps extends BuzzfeedQuizPost {}

/**
 * Represent an entire buzzfeed quiz in the social media emulator.
 * @param {object} props -- The buzz feed component inputs.
 * @param {string?} props.title The buzz feed quiz title.
 * @param {string?} props.prompt The buzz feed quiz prompt.
 * @param {BuzzfeedQuestion[]} props.questions -- The questions to display for this quiz.
 * @param {string} props.id -- The id prefix to use for elements on this quiz.
 * @param {ResponseSelectedCallback} props.onResponseSelected
 */
export function BuzzfeedQuiz(props: BuzzfeedQuizProps) {
  /** @type {BuzzfeedQuestion[]} */
  const questions = props.questions;
  const items = [];

  if (questions == null) {
    throw new Error(
      `Buzzfeed quiz data ${props.id} did not contain any questions`
    );
  }

  for (const question of questions) {
    items.push(
      <BuzzfeedQuestionComponent
        question={question}
        id={props.id}
        onResponseSelected={onResponseSelected}
        key={question.name}
      />
    );
  }

  return <div id={`${props.id}-buzzfeed-quiz`}>{items}</div>;
}

function onResponseSelected(
  question: BuzzfeedQuestion,
  response: BuzzfeedResponse
) {}
